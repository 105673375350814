<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>
        <!-- Producto -->
        <v-dialog v-model="verProductos" max-width="1200px">
          <v-card>
            <v-toolbar dark dense class="primary mb-1" flat>
              <v-toolbar-title>Productos</v-toolbar-title>
              <v-spacer />
              <v-text-field dense solo-inverted v-model.trim="brDescripcion" label="Buscar Productos"
                prepend-inner-icon="search" hide-details @keyup="listarProductoBuscar" clearable>
              </v-text-field>
              <v-spacer />
              <v-btn icon @click="ocultarProductos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <!-- Mostrar imagen de producto -->
              <v-dialog v-model="selectedZoom" max-width="350px">
                <v-avatar tile size="350">
                  <img :src="'data:image/png;base64,' + imagen_zoom" />
                </v-avatar>
              </v-dialog>
              <!-- Fin Mostrar imagen de producto -->

              <v-data-table :headers="cabeceraBusquedaProductos" :items="productos" item-key="iddetalle_compra"
                show-expand class="elevation-3" @dblclick:row="dblClickRepuesto" dense>
                <template v-slot:item.seleccionar="{ item }">
                  <v-icon @click="agregarDetalle(item)" color="primary">control_point</v-icon>
                </template>

                <template v-slot:item.imagen="{ item }">
                  <template>
                    <v-avatar tile size="27">
                      <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                    </v-avatar>
                  </template>
                </template>

                <template v-slot:item.codigos="{ item }">
                  <strong class="primary--text">{{ item.codigo_producto }} </strong> <br /> {{ item.codigo_producto2 }}
                </template>

                <template v-slot:item.marca="{ item }">
                  <strong class="error--text">{{ item.marca }}</strong>
                </template>

                <template v-slot:item.stock_actual="{ item }">
                  <strong>{{ item.stock_actual }}</strong>
                </template>

                <template v-slot:item.precio_compra="{ item }">
                  <div class="text-right">
                    <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}{{ item.precio_unit_compra | formatearNumero
                      }}</span>
                  </div>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">

                    <template v-if="item.serie" class="mr-2">
                      <strong>SERIE:</strong>
                      {{ item.serie }}
                    </template>

                    <template v-if="esAdministrador">
                      <strong>Precio Compra:</strong>
                      <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}
                        {{ item.precio_unit_compra.toFixed(2) | formatearNumero }}</span>
                      <template v-if="item.idtipo_servicio == 4">
                        <strong class="ml-2" v-if="esAdministrador">Precio Compra TC:</strong>
                        <span v-if="esAdministrador">S/ {{ (item.idtipo_servicio == 3 ?
          item.precio_unit_compra : item.precio_unit_compra *
          tc).toFixed(2) | formatearNumero
                          }}</span>
                      </template>
                      <br />
                    </template>

                    <strong>PROVEEDOR:</strong>
                    {{ item.proveedor.substring(0, 30) }}
                    <strong class="ml-4">FEC.INGRESO:</strong>
                    {{ item.fecha | formatearFecha }}
                    <strong>Pedido:</strong>
                    {{ item.idpedido }}
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Producto -->

        <!-- Listado Traslados -->
        <v-data-table :loading="loading" :headers="cabeceraListarTraslado" :items="pedidos" :items-per-page="15"
          item-key="idmovimiento" show-expand dense class="elevation-3" v-if="verNuevoPedido == 0">

          <template v-slot:top>
            <!-- Titulo y busqueda pedido persona -->
            <v-toolbar flat v-if="verNuevoPedido == 0">
              <v-toolbar-title class="hidden-sm-and-down">TRASLADOS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de pedido -->
              <v-text-field dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar" single-line
                hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates"
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()"
                  range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de pedido -->
              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-sm-and-down" small outlined>Nuevo</v-btn>
              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-md-and-up" fab x-small outlined>
                <v-icon>add</v-icon>
              </v-btn>
            </v-toolbar>
            <!-- Fin Titulo y busqueda pedido persona -->

            <!-- Modal anular traslado -->
            <v-dialog v-model="adModal" max-width="310">
              <v-card>
                <v-card-title class="headline">Anular: {{ serie_comprobante }}-{{ num_comprobante }}</v-card-title>
                <v-card-actions class="py-0 pb-4 justify-center">
                  <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
                  <v-btn color="error" @click="anular" small outlined>Anular</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal anular traslado -->
          </template>
          <!-- Opciones editar, eliminar pedido persona y realizar venta -->

          <template v-slot:item.numero="{ item }">{{ item.serie_comprobante + "-" + item.num_comprobante }}</template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado != 'Anulado'">
              <span>{{ item.estado }}</span>
            </template>

            <template v-else>
              <span class="error--text">{{ item.estado }}</span>
            </template>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
            <template v-if="item.estado != 'Anulado' && item.idusuario == usuario.idusuario">
              <v-btn small icon>
                <v-icon color="error" @click="anularMostrar(item)">delete</v-icon>
              </v-btn>
            </template>
            <!-- Menu para mas opciones de FE -->

            <template v-if="item.estado == 'Creado'">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small icon>
                    <v-icon v-on="on" color="primary">more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <!-- Guia Remision -->
                  <v-list-item link>
                    <v-list-item-content>
                      <v-list-item-title class="primary--text" @click="irGuiaRemision(item)">Generar GUÍA REMISIÓN
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- Fin Guia Remision -->
                </v-list>
              </v-menu>
            </template>
            <!-- Fin Menu para mas opciones de FE -->
          </template>
          <!-- Fin Opciones editar, eliminar pedido persona y realizar venta -->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Registrado por:</strong>
              {{ item.usuario }}
            </td>
          </template>
        </v-data-table>
        <!--Fin Listado Traslados -->

        <!-- Ventana crear y modificar traslado -->
        <v-card v-if="verNuevoPedido == 1" class="elevation-3">
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nuevo Traslado</v-toolbar-title>
            <v-spacer />
            <v-text-field dense solo-inverted @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
              label="Código Barra" maxlength="30" append-icon="qr_code_scanner" style="max-width: 220px;" hide-details>
            </v-text-field>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevoPedido">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="10" md="4" sm="4">
                  <v-autocomplete dense v-model="idreferencia" :items="selectSucursales" label="Establecimiento Destino"
                    :rules="[v => !!v || 'El establecimiento destino es requerido']" hide-details>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" md="1" sm="1">
                  <div class="text-center">
                    <v-btn @click="mostrarProductos()" x-small fab color="primary" outlined>
                      <v-icon>list</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-data-table :headers="cabeceraDetallesPedidos" :items="detalles" disable-pagination
                    hide-default-footer dense class="elevation-3">

                    <template v-slot:item.num="{ item }">
                      <td>{{ detalles.indexOf(item) + 1 }}</td>
                    </template>

                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="error" @click="eliminarItemDetallePedido(detalles, item)">delete_forever
                      </v-icon>
                    </template>

                    <template v-slot:item.stock_actual="{ item }">
                      <span class="font-weight-bold">{{ item.stock_actual }}</span>
                    </template>

                    <template v-slot:item.cantidad="{ item }">
                      <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                        v-model.number="item.cantidad"
                        :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.cantidad || 'Cantidad no puede ser > a stock']"
                        maxlength="6" hide-details class="center-input" @keypress="common.isNum($event)"
                        onClick="this.select()"></v-text-field>
                    </template>

                    <template v-slot:item.precio_unit_compra="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_compra"
                        :rules="[v => !!v && v >= item.precio_unit_compra2 || 'Requerido']" maxlength="9" hide-details
                        @keypress="common.numDec($event, item.precio_unit_compra, 4)" onClick="this.select()">
                      </v-text-field>
                    </template>

                    <template v-slot:item.subtotal="{ item }">
                      <div class="text-right">
                        <span class="font-weight-bold">{{ common.roundOut(item.cantidad * item.precio_unit_compra, 2) |
          formatearNumero
                          }}</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-container>
                    <v-row no-gutters align="end" justify="end">
                      <v-col cols="10" align="end">
                        <strong>Importe Total $</strong>
                      </v-col>
                      <v-col cols="2" align="end">
                        <strong>{{ importe_total = common.roundOut(parseFloat(calcularTotal), 2) |
          formatearNumero
                          }}</strong>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="ocultarNuevo()" small outlined>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarTraslado()" :loading="desabilitar" :disabled="desabilitar || !isValid"
              small outlined>
              <v-icon left>check</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Ventana crear y modificar traslado -->

        <!-- Modal impresión -->
        <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      pedidos: [],
      detalles: [],
      detalle_traslados: [],

      cabeceraListarTraslado: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "MOTIVO", value: "motivo", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "ESTAB. ORIGEN", value: "establecimiento_origen", sortable: false },
        { text: "ESTAB. DESTINO", value: "establecimiento_destino", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 120 },
      ],

      cabeceraDetallesPedidos: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        { text: "CÓDIGO", value: "codigo_producto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "CANTIDAD", value: "cantidad", align: "center", sortable: false },
        { text: "P/U COMPRA $", value: "precio_unit_compra", sortable: false },
        { text: "TOTAL $", value: "subtotal", sortable: false },
      ],

      cabeceraBusquedaProductos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "U/M", value: "idunidad_medida", sortable: false },
        { text: "CÓDIGOS", value: "codigos", sortable: false },
        { text: "NOMBRE", value: "descripcion", sortable: false },
        { text: "SERIE", value: "serie", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca", sortable: false, divider: true },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "P.COMPRA", value: "precio_compra", sortable: false },
      ],
      loading: false,

      buscar: "",
      codigo_barra: "",
      productos: [],

      brDescripcion: "",

      idpedido: "",
      idpersona: "",

      establecimiento_origen: "",
      establecimiento_destino: "",
      
      igv: 0,
      importe_total: 0,

      selectedZoom: false,
      imagen_zoom: "",

      verNuevoPedido: 0,
      verProductos: 0,

      idreferencia: "", // Destino
      selectSucursales: [],

      sucursal: "",
      persona: "",
      fecha: "",
      num_documento: "",

      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",

      comprobanteModal: 0,

      desabilitar: false,
      isValid: true,

      adModal: 0,
      adId: "",

      idtipo_documento: "", // dni=1 ó ruc=6

      stringPdf: "",
      blobPdf: "",

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(parseInt(me.detalles[i].cantidad == "" ? 0.0 : me.detalles[i].cantidad) * parseFloat(me.detalles[i].precio_unit_compra == "" ? 0.0 : me.detalles[i].precio_unit_compra), 2);
      }
      return resultado;
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
    verProductos(val) {
      val || this.ocultarProductos();
    },
    brDescripcion(newVal) {
      if (newVal === null) { this.brDescripcion = ""; }
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.listar();
    this.select();
  },

  methods: {
    ...mapActions(["snackBar"]),

    select() {
      let me = this;
      var sucursalesArray = [];
      axios.get("api/Sucursales/SelectDestino", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal)
        },
      })
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            me.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    //#region LISTAR PEDIDOS
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Movimientos/ListarTrasladoFiltro", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          buscar: me.buscar == "" ? "-" : me.buscar,
          fechaInicio: me.dates[0],
          fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.pedidos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    irGuiaRemision(item) {
      this.$router.push({
        name: "guias_remision",
        params: {
          venta: true,
          idmovimientoventa: item.idmovimiento,
          idpersonaventa: item.idpersona,
          idpedidoventa: item.idpedido,
          numero: item.serie_comprobante + "-" + item.num_comprobante,
          idmotivotraslado: "04",
          mottraslado: "TRASLADO ENTRE ESTABLECIMIENTOS DE LA MISMA EMPRESA"
          //idsucursaldestino: item.idreferencia
        },
      });
    },
    //#endregion

    //#region PEDIDO GUARDAR, EDITAR, MOSTRAR VENTANA Y ELIMINAR DETALLE
    guardarTraslado() {
      let me = this;
      // Validamos detalle
      if (me.detalles.length <= 0) { return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." }); }

      me.desabilitar = true;
      me.detalle_traslados = [];
      me.convertirDetalleTraslado();

      axios.post("api/Movimientos/CrearTraslado", {
        idsucursal: parseInt(me.usuario.idsucursal),
        idusuario: parseInt(me.usuario.idusuario),
        idpersona: 1,
        importe_total: parseFloat(me.importe_total),
        motivo: "Traslado",
        idreferencia: me.idreferencia, // Sucursal destino
        detalle_traslados: me.detalle_traslados,
      })
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
          me.ocultarNuevo();
          me.listar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.listar();
        });
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios.get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear
          detallesArray.map(function (x) {
            me.detalles.push({
              idproducto: x.idproducto,
              codigo_producto: x.codigo_producto, // FE
              //categoria: x.categoria,
              descripcion: x.codigo_producto + " " + x.descripcion + (x.serie == "" ? "" : " S/N:" + x.serie) + " " + x.categoria + " " + x.marca,
              descripcion_adicional: x.descripcion_adicional,
              //marca: x.marca,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              idunidad_medida: x.idunidad_medida,
              cantidad: x.cantidad,
              ref_idpedido: x.ref_idpedido,
              //valor_unit_venta: x.valor_unit_venta.toFixed(2),
              //descto_item: x.descto_item.toFixed(2),
              //valor_venta: x.valor_venta.toFixed(2),
              //precio_venta: x.precio_venta.toFixed(2),
              //idprod_sunat: x.idprod_sunat,
            });
          });
          me.comprobanteModal == 1 ? me.crearPDF() : ""; // Solo para pedido pdf
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    convertirDetalleTraslado() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_traslados.push({
          idproducto: me.detalles[i].idproducto,
          iddetalle_compra: me.detalles[i].iddetalle_compra,
          cantidad: parseInt(me.detalles[i].cantidad),
          descripcion_adicional: me.detalles[i].descripcion_adicional,
          serie: me.detalles[i].serie,
          valor_unit_compra: parseFloat(me.detalles[i].valor_unit_compra),
          precio_unit_compra: parseFloat(me.detalles[i].precio_unit_compra),
        });
      }
    },

    mostrarNuevoPedido() {
      this.verNuevoPedido = 1;
    },

    ocultarNuevo() {
      this.verNuevoPedido = 0;
      this.desabilitar = false;
      this.limpiar();
    },

    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    /* Modal Anular */
    anular() {
      let me = this;
      axios.put("api/Movimientos/AnularTraslado/" + me.adId)
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data });
          me.adModal = 0;
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "error", msg: error.response.data });
          me.adModal = 0;
          me.adId = "";
        });
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.adId = item.idmovimiento;
    },

    anularCerrar() {
      this.adModal = 0;
    },
    /* Fin Modal Anular*/
    //#endregion

    //#region IMPRESIÓN
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "Traslado-" + this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.sucursal = item.sucursal;
      this.persona = item.persona;
      this.num_documento = item.num_documento;

      this.importe_total = item.importe_total;

      this.motivo = item.motivo;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.fecha = item.fecha;
      this.idpedido = item.idpedido;

      this.establecimiento_origen = item.establecimiento_origen;
      this.establecimiento_destino = item.establecimiento_destino;

      this.comprobanteModal = 1;
      this.listarDetallePedidos(item.idpedido);
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },
    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion IMPRESION

    //#region PRODUCTO
    buscarCodigoBarra() {
      let me = this;

      axios.get("api/Pedidos/BuscarCodigoBarraVenta/" + parseInt(me.usuario.idsucursal) + "/" + me.codigo_barra)
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
          me.snackBar({ cl: "info", msg: "Se agrego " + response.data.descripcion + " al detalle." });
          me.codigo_barra = "";
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "warning", msg: "No hay registro de compra para el producto." });
          me.codigo_barra = "";
        });
    },

    listarProductoBuscar() {
      let me = this;

      axios.get("api/Pedidos/ListarProductoVenta", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          descripcion: me.brDescripcion == "" ? "-" : me.brDescripcion,
        },
      })
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    dblClickRepuesto(event, { item }) {
      this.agregarDetalle(item);
    },

    agregarDetalle(data = []) {
      let me = this;
      // Buscar el índice del detalle existente con el mismo iddetalle_compra
      const indiceExistente = me.detalles.findIndex(detalle => detalle.iddetalle_compra === data["iddetalle_compra"]);

      if (indiceExistente !== -1) {
        // Si el detalle ya existe, sumar la cantidad
        me.detalles[indiceExistente].cantidad += 1;
      } else {
        me.detalles.push({
          idproducto: data["idproducto"],
          iddetalle_compra: data["iddetalle_compra"],
          codigo_producto: data["codigo_producto"], // FE
          //categoria: data["categoria"],
          //marca: data["marca"],
          serie: data["serie"],
          descripcion: data["codigo_producto"] + " " + data["descripcion"] + (data["serie"] == "" ? "" : " S/N:" + data["serie"]) + " " + data["categoria"] + " " + data["marca"],
          descripcion_adicional: data["descripcion"] + (data["serie"] == "" ? "" : " S/N:" + data["serie"]),
          stock_actual: data["stock_actual"],
          cantidad: 1,
          // Todo en Dolares
          valor_unit_compra: me.common.roundOut((data["idtipo_servicio"] == 4 ? data["valor_unit_compra"] : data["valor_unit_compra"] / me.tc), 4),
          precio_unit_compra: me.common.roundOut(data["idtipo_servicio"] == 4 ? data["precio_unit_compra"] : (data["precio_unit_compra"] / me.tc), 4).toFixed(4),
          precio_unit_compra2: me.common.roundOut(data["idtipo_servicio"] == 4 ? data["precio_unit_compra"] : (data["precio_unit_compra"] / me.tc), 4).toFixed(4), // > compra
        });
      }
    },

    mostrarProductos() {
      this.verProductos = 1;
    },

    ocultarProductos() {
      this.verProductos = 0;
      this.productos = [];
      this.brDescripcion = "";
      this.imagen_zoom = "";
    },
    //#endregion PRODUCTO

    limpiar() {
      this.igv = parseFloat(this.datos.igv); // El igv formateado

      this.idpedido = "";
      this.idpersona = "";
      this.num_documento = "";
      this.tipo_pedido = "";

      this.idreferencia = "";
      this.importe_total = 0;

      this.motivo = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";

      this.detalles = [];
      this.detalle_traslados = [];

      this.codigo_barra = "";
      this.productos = [];
      this.brDescripcion = "";

      this.establecimiento_origen = "";
      this.establecimiento_destino = "";

      this.idtipo_documento = ""; // dni=1 ó ruc=6
      this.stringPdf = "";
      this.blobPdf = "";
    },

    crearPDF() {
      var columns = [
        { header: "CANT.", dataKey: "cantidad" },
        { header: "U/M", dataKey: "idunidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "RPC", dataKey: "ref_idpedido" },
      ];
      var body = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad,
          idunidad_medida: this.detalles[i].idunidad_medida,
          descripcion: this.detalles[i].descripcion,
          ref_idpedido: this.detalles[i].ref_idpedido,
        });
      }

      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos persona
          doc.text("Razón Social: " + this.persona, 40, 75);
          doc.text("Doc. N°: " + this.num_documento, 40, 90);
          doc.text("Motivo: " + this.motivo, 150, 90);

          doc.text("Estab. Origen: " + this.establecimiento_origen, 415, 75);
          doc.text("Fecha: " + moment(this.fecha).format("DD/MM/YYYY"), 280, 90);
          doc.text("Estab. Destino:" + this.establecimiento_destino, 415, 90);
          // Fin Datos persona

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text("TRASLADO", doc.internal.pageSize.width / 2 + 140, 42, "center");
          doc.text(this.serie_comprobante + "-" + this.num_comprobante, doc.internal.pageSize.width / 2 + 140, 54, "center");
          // Fin Datos documento
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            "center"
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt", [595, 842]);

      doc.autoTable({
        //theme: "striped",
        columns,
        body,
        margin: { top: 100 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          cantidad: { cellWidth: 32, halign: "center" },
          idunidad_medida: { cellWidth: 40, halign: "center" },
          descripcion: { cellWidth: "auto" },
          ref_idpedido: { cellWidth: 30, halign: "right" },
        },
      });
      // 515 ancho hoja
      doc.setProperties({
        title: this.serie_comprobante + "-" + this.num_comprobante + ".pdf",
      });
      addHeaders(doc);
      addFooters(doc);

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },
  },

  mounted() { },
};
</script>

<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>