import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
// Globales
import numeral from "numeral";
import moment from "moment";
import printJS from "print-js"; // Print pdf base64 -> Requerido
import autoTable from "jspdf-autotable"; // Tabla pdf jsPDF -> Requerido
import common from './common';
Vue.prototype.common = common;
// Fin Globales

Vue.config.productionTip = false
axios.defaults.baseURL = 'https://universal.urpisoft.pe/'


axios.interceptors.request.use(
  function (config) {
    const auth_token = localStorage.getItem('token');
    if (auth_token) {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    //console.log(config)
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

// Multiples requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {

    if (isRefreshing) {
      try {
        const token = await new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
        originalRequest.headers['Authorization'] = 'Bearer ' + token
        return axios(originalRequest)
      } catch (err) {
        return Promise.reject(err)
      }
    }

    originalRequest._retry = true;
    isRefreshing = true;
    // Validamos token y refreshtoken
    const tokens = {
      token: localStorage.getItem("token"),
      refreshToken: localStorage.getItem("refreshtoken"),
    };
    return new Promise(function (resolve, reject) {
      axios.post("api/Usuarios/Refresh", tokens)
        .then(({ data }) => {
          const token = data.token;
          const refreshtoken = data.refreshtoken;
          const setting = data.setting;
          const avatar = data.avatar;
          const logo = data.logo;
          store.dispatch("guardarToken", token);
          store.dispatch("guardarRefreshToken", refreshtoken);
          store.dispatch("guardarSetting", setting);
          store.dispatch("guardarAvatar", avatar);
          store.dispatch("guardarLogo", logo);
          //store.dispatch("cargarNotificaciones");

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
          processQueue(null, data.token);
          resolve(axios(originalRequest));
          console.log("Token Actualizado");
        })
        .catch((err) => {
          console.log("Token Erroneo");
          processQueue(err, null);
          reject(err);
          store.dispatch("salir");
        })
        .then(() => { isRefreshing = false })
    })
  }
  return Promise.reject(error);
});

// Globales
// Formatear Moneda
Vue.filter("formatearNumero", function (value) {
  return numeral(value).format("###,##0.00");
});
// Formatear Fecha
Vue.filter("formatearFecha", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

// Formatear Fecha
Vue.filter("formatearFechaHm", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

// Solo numeros y espacio
Vue.directive('numspace',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
        vnode.componentInstance.$emit('input', e.target.value.replace(/[^0-9 ]/g, ""))
      })
    }
  })
// Capitalizar primera letra
/* Vue.directive('capitalize',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.replace(/^\w/, (c) => c.toUpperCase())
        vnode.componentInstance.$emit('input', e.target.value.replace(/^\w/, (c) => c.toUpperCase()))
      })
    }
  }) */
// String a minusculas
Vue.directive('lowercase',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.toLowerCase()
        vnode.componentInstance.$emit('input', e.target.value.toLowerCase())
      })
    }
  })
// String a mayusculas
Vue.directive('uppercase',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.toUpperCase()
        vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
      })
    }
  })
// Fin Globales

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
